import React from 'react'

import type { IFluidImageWithName } from '@type-defs/graphql'

interface IHomePageContext {
  insuranceLogos: IFluidImageWithName[]
}

const HomePageContext = React.createContext<IHomePageContext>({ insuranceLogos: [] })

export default HomePageContext
