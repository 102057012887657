import React from 'react'

import H2 from '@components/core/headings/h2'
import Section from '@components/core/section'
import Paragraph from '@components/core/paragraph'
import TextLink from '@components/core/text-link'

import FavoriteServicesList from './favorite-services-list'

export default function FavoriteServices() {
  return (
    <Section id="oblibene-sluzby">
      <H2>Oblíbené služby</H2>

      <Paragraph>
        Přehled všech nabízených služeb a jejich detailnější popis naleznete v&nbsp;
        <TextLink to="/cenik-sluzeb">přehledu a ceníku služeb</TextLink>.
      </Paragraph>

      <FavoriteServicesList />
    </Section>
  )
}
