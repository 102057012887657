import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import H1 from '@components/core/headings/h1'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const HeroSection = styled(BackgroundImage)`
  /* margin-top: ${toRem(60)}; fixed header offset */
  padding: 0 ${theme.spacing[1]} ${toRem(90)};
  height: calc(100vh - ${toRem(60)});
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: ${theme.breakpoints.xs}) {
    padding: 0 ${theme.spacing[1]} ${toRem(130)};
  }

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding: 0 ${theme.spacing[4]} ${theme.spacing[4]};
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    padding: 0 ${theme.spacing[5]} ${theme.spacing[5]};
  }
`

export const HeroIntroductionContainer = styled.div`
  width: 60%;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`

export const HeroHeading = styled(H1)`
  color: ${theme.colors.white[900]};
`

export const LogoWrapper = styled.div`
  max-height: ${toRem(300)};
  max-width: ${toRem(920)};
  margin-bottom: ${theme.spacing[2]};

  @media screen and (max-width: ${theme.breakpoints.xxl}) {
    max-width: ${toRem(770)};
  }
`

export const HorizontalLine = styled.hr`
  width: 100%;
  border: ${toRem(1)} solid ${theme.colors.primary};
`
