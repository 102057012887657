import React from 'react'
import { useMedia } from 'react-use'
import type { IFluidImage } from '@type-defs/graphql'

import * as S from './styles'

import LogoIconWithText from '@components/core/icons/logo-with-text'

import theme from '@theme'

interface IHeroBannerProps {
  fluidImage: IFluidImage
}

export default function HeroBanner({ fluidImage }: IHeroBannerProps) {
  const isMobile = useMedia(`(max-width: ${theme.breakpoints.sm})`)

  const gradientDirection = isMobile ? 'to top' : 'to right'

  return (
    <S.HeroSection
      id="hero"
      Tag="section"
      fluid={[
        `linear-gradient(${gradientDirection}, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.2))`,
        fluidImage,
      ]}
    >
      <S.HeroIntroductionContainer>
        <S.HeroHeading>
          <S.LogoWrapper>
            <LogoIconWithText />
          </S.LogoWrapper>
          Dentální hygiena
          <br />
          Rýmařov
        </S.HeroHeading>
      </S.HeroIntroductionContainer>
      <S.HorizontalLine />
    </S.HeroSection>
  )
}
