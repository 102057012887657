/* eslint-disable no-nested-ternary */
import React from 'react'
import type { FluidObject } from 'gatsby-image'

import * as S from './styles'

import TextButton from '@components/core/text-button'

// import { contacts } from '@resources/data/contact'

import theme from '@theme'

interface IFavoriteServiceCardProps {
  title: string
  description: string
  fluidImage: FluidObject
  timeRange: string

  price?: number | number[]
}

export default function FavoriteServiceCard({
  title,
  description,
  fluidImage,
  timeRange,
  price,
}: IFavoriteServiceCardProps) {
  return (
    <S.ServiceCard>
      <S.ServiceCardImage fluid={fluidImage} alt={title} />
      <S.ServiceCardHeader>{title}</S.ServiceCardHeader>
      <S.ServiceCardBody>
        <S.ServiceCardDescription>{description}</S.ServiceCardDescription>
        <S.ServiceCardInfoList>
          <li>
            Časová náročnost:&nbsp;<span>{timeRange}</span>
          </li>
          <li>
            Cena:&nbsp;
            <span>
              {price
                ? Array.isArray(price)
                  ? `${price[0]} - ${price[1]},-`
                  : `${price},-`
                : 'Dle náročnosti'}
            </span>
          </li>
        </S.ServiceCardInfoList>
      </S.ServiceCardBody>
      <S.ServiceCardFooter>
        <TextButton
          // as="a"
          // href={contacts.onlineBookingUrl}
          // target="_blank"
          // rel="noopener noreferrer"
          disabled
          color={theme.colors.primary}
          hoverColor={theme.colors.pink[200]}
        >
          Objednat termín
        </TextButton>
      </S.ServiceCardFooter>
    </S.ServiceCard>
  )
}
