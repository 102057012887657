import React from 'react'

import FavoriteServiceCard from './favorite-service-card'

import * as S from './styles'

import useServicesData from '@hooks/use-services-data'

export default function FavoriteServicesList() {
  const services = useServicesData({ limit: 4 })

  return (
    <S.ServicesList>
      {services.map((service) => (
        <li key={service.id}>
          <FavoriteServiceCard
            title={service.name}
            fluidImage={service.fluidImage}
            description={service.shortDescription}
            timeRange={service.timeRange}
            price={service.price}
          />
        </li>
      ))}
    </S.ServicesList>
  )
}
