interface InsurancePartnerEntry {
  name: string
  url: string
  /**
   * should match image located in src/images/insurance-logos
   */
  logoKey: string
}

export const insurancePartners: InsurancePartnerEntry[] = [
  {
    name: 'Česká průmyslová zdravotní pojišťovna',
    url: 'https://www.cpzp.cz/',
    logoKey: 'cpzp-logo.png',
  },
  {
    name: 'Oborová zdravotní pojišťovna',
    url: 'https://www.ozp.cz/',
    logoKey: 'ozp-logo.png',
  },
  {
    name: 'Revirní bratrská pojišťovna',
    url: 'https://www.rbp213.cz/',
    logoKey: 'rbp-logo.png',
  },
  {
    name: 'Vojenská zdravotní pojišťovna',
    url: 'https://www.vozp.cz/',
    logoKey: 'vozp-logo.png',
  },
  {
    name: 'Všeobecná zdravotní pojišťovna',
    url: 'https://www.vzp.cz/',
    logoKey: 'vzp-logo.png',
  },
  {
    name: 'Zdravotní pojišťovna ministerstva vnitra ČR',
    url: 'https://www.zpmvcr.cz/',
    logoKey: 'zpmvcr-logo.png',
  },
  {
    name: 'Zdravotní pojišťovna škoda',
    url: 'https://www.zpskoda.cz/',
    logoKey: 'zps-logo.png',
  },
]
