import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import type { IFixedObject } from 'gatsby-background-image'

import SEO from '@components/core/seo'
import Layout from '@components/core/layout'
import HeroBanner from '@components/pages/home/hero-banner'
import FavoriteServices from '@components/pages/home/favorite-services'
import AboutMe from '@components/pages/home/about-me'
import InsuranceList from '@components/pages/home/insurance-list'

import type { IConnection, IFluidImageWithName, IFluidImage } from '@type-defs/graphql'
import HomePageContext from '@contexts/home-page'

interface ISharpFluidImage {
  childImageSharp: {
    fluid: IFluidImageWithName
  }
}

interface IHomePageProps {
  data: {
    insuranceLogos: IConnection<ISharpFluidImage>
    backgroundImage: {
      childImageSharp: {
        fluid: IFluidImage
        fixed: IFixedObject
      }
    }
  }
}

export default function HomePage({ data }: IHomePageProps) {
  const context = useMemo(
    () => ({
      insuranceLogos: data.insuranceLogos.edges.map((edge) => edge.node.childImageSharp.fluid),
    }),
    [data]
  )

  return (
    <>
      <SEO image={data.backgroundImage.childImageSharp.fixed} />
      <HomePageContext.Provider value={context}>
        <Layout>
          <HeroBanner fluidImage={data.backgroundImage.childImageSharp.fluid} />

          <FavoriteServices />

          <AboutMe />

          <InsuranceList />
        </Layout>
      </HomePageContext.Provider>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    insuranceLogos: allFile(filter: { relativeDirectory: { eq: "insurance-logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "hero-image.jpeg" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1641, height: 1108) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
