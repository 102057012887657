import styled from 'styled-components'
import { Link } from 'gatsby'

import { link } from '@theme/typography'

const TextLink = styled(Link)`
  ${link}
`

export default TextLink
