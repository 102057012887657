import React, { useContext, useMemo } from 'react'
import GatsbyImage from 'gatsby-image'
import type { FluidObject } from 'gatsby-image'

import H2 from '@components/core/headings/h2'
import Paragraph from '@components/core/paragraph'
import TextLink from '@components/core/text-link'
import HomePageContext from '@contexts/home-page'

import * as S from './styles'

import { insurancePartners } from './data'
import { INSURANCE_CONTRIBUTIONS_ID } from '@utils/constants'

export default function InsuranceList() {
  const { insuranceLogos } = useContext(HomePageContext)

  const insurancePartnersWithLogos = useMemo(
    () =>
      insurancePartners.map((insurancePartner) => ({
        ...insurancePartner,
        logo: insuranceLogos.find((logo) => logo.originalName === insurancePartner.logoKey),
      })),
    [insuranceLogos]
  )

  return (
    <S.InsurancePartnersSection>
      <H2>Pojišťovny přispívající na dentální hygienu</H2>
      <S.InsuranceList>
        {insurancePartnersWithLogos.map((insurancePartner) => (
          <S.InsuranceListItem key={insurancePartner.name}>
            <a href={insurancePartner.url} target="_blank" rel="noopener noreferrer">
              <GatsbyImage
                fluid={insurancePartner.logo as FluidObject}
                alt={insurancePartner.name}
              />
            </a>
          </S.InsuranceListItem>
        ))}
      </S.InsuranceList>
      <Paragraph>
        Výše příspěvků jednotlivých pojišťoven naleznete v&nbsp;
        <TextLink to={`/cenik-sluzeb#${INSURANCE_CONTRIBUTIONS_ID}`}>ceníku služeb</TextLink>.
      </Paragraph>
    </S.InsurancePartnersSection>
  )
}
