import styled from 'styled-components'

import Card from '@components/core/card'
import CardImage from '@components/core/card-image'
import CardHeader from '@components/core/card-header'
import CardBody from '@components/core/card-body'
import Paragraph from '@components/core/paragraph'
import CardFooter from '@components/core/card-footer'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const ServicesList = styled.ul`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[2]};

  @media screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
  }
`

export const ServiceCard = styled(Card)`
  display: grid;
  grid-template-rows: ${toRem(250)} auto ${toRem(100)};
  grid-template-areas:
    'image'
    'header'
    'body'
    'footer';
`

export const ServiceCardImage = styled(CardImage)`
  grid-area: image;
`

export const ServiceCardHeader = styled(CardHeader)`
  grid-area: header;
`

export const ServiceCardBody = styled(CardBody)`
  grid-area: body;
`

export const ServiceCardDescription = styled(Paragraph)`
  margin-bottom: ${theme.spacing[1]};
`

export const ServiceCardInfoList = styled.ul`
  li {
    margin-bottom: ${theme.spacing[0]};
  }
  span {
    font-weight: 500;
  }
`

export const ServiceCardFooter = styled(CardFooter)`
  grid-area: footer;
`
