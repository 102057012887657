import styled from 'styled-components'
import type { FluidObject } from 'gatsby-image'

import Card from '@components/core/card'
import CardImage from '@components/core/card-image'
import CardHeader from '@components/core/card-header'
import CardBody from '@components/core/card-body'
import CardFooter from '@components/core/card-footer'
import Paragraph from '@components/core/paragraph'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const ServiceCard = styled(Card)`
  display: grid;
  grid-template-rows:
    ${toRem(250)} minmax(${toRem(50)}, max-content) auto
    ${toRem(42)};
  grid-template-areas:
    'image'
    'header'
    'body'
    'footer';

  @media screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-rows:
      ${toRem(250)} minmax(${toRem(50)}, max-content) minmax(max-content, ${toRem(120)})
      ${toRem(42)};
  }

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    grid-template-rows:
      ${toRem(250)} minmax(${toRem(50)}, max-content) minmax(max-content, ${toRem(100)})
      ${toRem(42)};
  }

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    grid-template-rows:
      ${toRem(250)} minmax(${toRem(50)}, max-content) minmax(max-content, ${toRem(120)})
      ${toRem(42)};
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    grid-template-rows:
      ${toRem(250)} minmax(${toRem(50)}, max-content) minmax(max-content, ${toRem(100)})
      ${toRem(42)};
  }
`

export const ServiceCardImage = styled(CardImage)<{ fluid: FluidObject }>`
  grid-area: image;
`

export const ServiceCardHeader = styled(CardHeader)`
  grid-area: header;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ServiceCardBody = styled(CardBody)`
  grid-area: body;
`

export const ServiceCardDescription = styled(Paragraph)`
  margin-bottom: ${theme.spacing[1]};
`

export const ServiceCardInfoList = styled.ul`
  li {
    margin-bottom: ${theme.spacing[0]};
  }
  span {
    font-weight: 500;
  }
`

export const ServiceCardFooter = styled(CardFooter)`
  grid-area: footer;
`
