import styled from 'styled-components'

import Section from '@components/core/section'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const InsurancePartnersSection = styled(Section)`
  background-color: ${theme.colors.white[900]};
`

export const InsuranceList = styled.ul`
  display: grid;
  margin-bottom: ${theme.spacing[2]};
  border-radius: ${toRem(8)};
  grid-template-columns: repeat(1, 0.6fr);
  grid-gap: ${theme.spacing[2]};
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    grid-template-columns: repeat(7, 1fr);
  }
`

export const InsuranceListItem = styled.li``
