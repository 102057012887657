import styled from 'styled-components'
import Section from '@components/core/section'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const AboutMeSection = styled(Section)`
  background-color: ${theme.colors.white[900]};
`

export const Grid = styled.div`
  display: grid;
  gap: ${theme.spacing[1]};

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const GridItem = styled.div`
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    padding: 0 0 0 ${theme.spacing[2]};

    &:nth-child(odd) {
      padding: 0 ${theme.spacing[2]} 0 0;
    }

    &:first-child {
      border-right: ${toRem(2)} solid ${theme.colors.primary};
    }
  }
`

export const ExperienceList = styled.ul`
  margin: 0 ${toRem(20)};
  list-style: circle;

  > li {
    margin-bottom: ${theme.spacing[1]};
  }
`
