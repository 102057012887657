import React from 'react'

import H2 from '@components/core/headings/h2'
import H3 from '@components/core/headings/h3'
import Paragraph from '@components/core/paragraph'

import * as S from './styles'

export default function AboutMe() {
  return (
    <S.AboutMeSection>
      <H2>O mně</H2>
      <S.Grid>
        <S.GridItem>
          <H3>Vzdělání</H3>
          <Paragraph>Petra Hudcová, DiS.</Paragraph>
          <Paragraph>
            Jsem diplomovaná dentální hygienistka. Vystudovala jsem střední zdravotnickou školu v
            Opavě a následně v roce 2018 absolvovala VOŠ v Brně v oboru diplomovaná dentální
            hygienistka.
          </Paragraph>
          <Paragraph>
            V roce 2018 jsem začala spolupracovat se třemi pracovištěmi v Brně. Na Parodontologii
            s.r.o. pod vedením MUDr. Veroniky Filipové, v Komárově v Ordinaci zubního lékaře MDDr.
            Olgy Frydrychové a v Teridentu pod vedením MDDr. Terezy Dvořákové.
          </Paragraph>
          <Paragraph>
            Od července 2020 vedu a pracuji ve své Ordinaci dentálni hygieny v Rýmařově.
          </Paragraph>
        </S.GridItem>
        <S.GridItem>
          <H3>Praxe</H3>
          <S.ExperienceList>
            <li>Parodontologie 2018-2020 - Parodontologie s.r.o., MUDr. Veronika Filipová</li>
            <li>Ordinace zubního lékaře 2018-2020 - MDDr. Olga Frydrychová</li>
            <li>Ordinace zubního lékaře 2018-2019 - Terident, MDDr. Tereza Dvořáková</li>
          </S.ExperienceList>
        </S.GridItem>
      </S.Grid>
    </S.AboutMeSection>
  )
}
